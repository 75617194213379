<style lang="scss">
.zielgruppenkalulator {
  iframe {
    border: 0;
    width: 100%;
    min-height: 660px;
  }
}
</style>

<template>
  <div class="zielgruppenkalulator content container">
    <h1>Zielgruppe</h1>
    <div class="rechner-all">
      <iframe src="/zg-rechner/index.html"></iframe>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Zielgruppenrechner',
});
</script>
